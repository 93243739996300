<template>
  <section id="reels">
    <!-- TITLE | BACK BUTTON -->
    <div>
      <b-row>
        <b-col class="d-flex justify-content-between">
          <h1>
            {{ $t("reels.reelsTitle") }}
          </h1>
          <b-button
            variant="danger"
            class="text-nowrap"
            @click="redirectReels()"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </b-col>
      </b-row>
      <h5 class="text-primary">
        {{ $t("reels.reelsSubtitle") }}
      </h5>
    </div>

    <b-row
      class="mt-3"
      align-v="stretch"
    >
      <b-col
        cols="12"
        align-self="stretch"
      >
        <!-- UPLOAD FILE BY BUK -->
        <b-card
          v-if="showDropCard"
          class="dropContainer p-5 mb-1 text-center cursor-pointer color-black"
          style="background-color: white"
          @click="selectFile()"
          @dragenter.prevent
          @dragover.prevent
          @drop.prevent="addReelFile"
        >
          <b-row>
            <b-col>
              <h4 style="color: black">
                {{ $t("reels.dropReelToCard") }}
              </h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button variant="primary">
                {{ $t('reels.uploadReel') }}
              </b-button>
            </b-col>
          </b-row>
          <b-form-file
            id="fileInput"
            v-model="fileInput"
            class="mr-1 d-none"
            accept="video/*"
            multiple
          />
        </b-card>
        <!-- UPLOAD FILE BY URL -->
        <b-row
          v-if="showUrlInput"
          class="form-group"
        >
          <b-col class="d-flex">
            <b-form-input
              id="newUrlInput"
              v-model="newUrl"
              class="mr-1"
              :state="newUrlValidation"
              :placeholder="$t('reels.uploadUrl')"
              type="url"
            />
            <b-button
              class="text-nowrap"
              variant="primary"
              @click="addReelUrl()"
            >
              {{ $t('reels.loadUrl') }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- REELS COUNT -->
    <b-row
      class="mb-3"
    >
      <b-col
        class="d-flex justify-content-end"
      >
        {{ nReel }} {{ $t('reels.reelUp') }}
      </b-col>
    </b-row>
    <!-- ReelsUpload COMPONENT -->
    <b-row
      v-if="uploadFilesList.length > 0 || uploadUrlsList.length > 0"
      class="mt-3"
    >
      <b-col>
        <reels-upload
          ref="reelsUpload"
          :files-upload="uploadFilesList"
          :urls-upload="uploadUrlsList"
          @cleanResourcesUploaded="cleanResourcesUploaded"
          @emptyReels="emptyReels"
          @recentUpload="recentUpload"
        />
      </b-col>
    </b-row>
    <!-- REELS TABLE -->
    <b-card v-if="searchFiles.length > 0">
      <b-card-header>
        <b-row class="mb-2">
          <b-col>
            <h2>{{ $t('freshUpload') }}</h2>
            <h6 class="text-primary">
              {{ $t('reels.lastReels') }}
            </h6>
          </b-col>
        </b-row>
      </b-card-header>
      <b-col
        style="padding: 1.5rem"
        class="pt-0"
      >
        <!-- SEARCHBAR -->
        <b-row class="mb-2">
          <b-col class="d-flex">
            <b-form-input
              v-model="search"
              :placeholder="$t('Search')"
              @keyup.enter="searching()"
            />
            <b-button
              class="ml-1 text-nowrap"
              variant="primary"
              @click="searching()"
            >
              {{ $t('Search') }}
            </b-button>
          </b-col>
        </b-row>
        <!-- SEARCH RESULTS -->
        <b-row class="d-flex flex-wrap">
          <b-col>
            <b-table
              :striped="true"
              sticky-header="500px"
              :no-border-collapse="true"
              responsive="sm"
              :items="searchResults ? searchResults : paginatedData"
              :fields="columns"
            >
              <!-- ICON -->
              <template #cell(icon)="data">
                <div>
                  <video
                    id="demo"
                    class="video-js vjs-default-skin vjs-big-play-centered cursor-pointer"
                    style="width: fit-content;"
                    data-setup="{&quot;liveui&quot;: true}"
                    preload="metadata"
                    :src="data.item.fileUrl"
                    @click.stop="showModal('playReelModal', data.item)"
                  />
                </div>
              </template>
              <!-- REEL NAME -->
              <template #cell(name)="data">
                <div class="text-nowrap customColumn">
                  {{ data.item.name }}
                </div>
              </template>
              <!-- DESCRIPTION -->
              <template #cell(description)="data">
                <div class="text-nowrap customColumn">
                  {{ data.item.description }}
                </div>
              </template>
              <!-- VIEWS -->
              <template #cell(nViews)="data">
                <div class="text-nowrap">
                  {{ data.item.numViews }}
                </div>
              </template>
              <!-- UPLOAD DATE -->
              <template #cell(date)="data">
                <div class="text-nowrap">
                  {{ formatDate(data.item.createdAt) }}
                </div>
              </template>
              <!-- ACTIVE -->
              <template #cell(active)="data">
                <b-badge
                  v-b-tooltip.hover.top.v-primary="$t('reels.desactivateReel')"
                  class="cursor-pointer"
                  :variant="data.item.isActive ? 'success' : 'danger'"
                  @click.stop.prevent="updateReelStatus(data.item)"
                >
                  {{ data.item.isActive ? $t('dataGeneric.active') : $t('dataGeneric.inactive') }}
                </b-badge>
              </template>
              <!-- BUTTONS -->
              <template #cell(actions)="data">
                <div class="text-nowrap d-flex pl-2">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="17"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item @click.stop="showModal('playReelModal', data.item)">
                      <div class="d-flex">
                        <feather-icon
                          icon="EyeIcon"
                          class="text-success mr-2"
                          size="18"
                        />
                        {{ $t('see') }}
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item @click.stop="showModal('editReelModal',data.item)">
                      <div class="d-flex">
                        <feather-icon
                          icon="Edit2Icon"
                          class="text-warning mr-2"
                          size="18"
                        />
                        {{ $t('dataGeneric.edit') }}
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item @click.stop="downloadReel(data.item)">
                      <div class="d-flex">
                        <feather-icon
                          icon="DownloadIcon"
                          class="text-info mr-2"
                          size="18"
                        />
                        {{ $t('download') }}
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item @click.stop="deleteReel(data.item.id)">
                      <div class="d-flex">
                        <feather-icon
                          icon="Trash2Icon"
                          class="text-danger mr-2"
                          size="18"
                        />
                        {{ $t('dataGeneric.delete') }}
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <!-- SEARCH RESULTS PAGINATION -->
        <b-row>
          <b-col class="d-flex ">
            <b-pagination
              v-if="rows > perPage"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              size="lg"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-card>
    <!-- PLAY REEL MODAL-->
    <b-modal
      ref="playReelModal"
      size="lg"
      hide-footer
      :title="$t('reels.viewReel')"
    >
      <div class="d-block text-center">
        <view-file-modal
          :datos="playReel"
          :calculate-duration="false"
        />
      </div>
    </b-modal>
    <!-- EDIT REEL MODAL -->
    <b-modal
      v-if="playReel"
      ref="editReelModal"
      size="custom"
      hide-footer
      :title="$t('reels.editReel')"
    >
      <div class="d-flex">
        <b-col cols="8">
          <b-tabs
            v-if="languageService"
            pills
            lazy
            vertical
          >
            <b-tab
              v-for="i in locales"
              :key="i.locale"
            >
              <!-- LANGUAGES SELECTION -->
              <template #title>
                <div
                  class="d-flex"
                  style="flex:auto"
                >
                  <b-img
                    :src="i.img"
                    height="14px"
                    width="22px"
                    :alt="i.locale"
                  />
                  <strong class="ml-50">{{ i.name }}</strong>
                </div>
              </template>
              <!-- EDIT REEL FORM -->
              <reels-edit
                :language="i.locale"
                :base="base"
                :original-object="playReel"
                @editUploadReel="editUploadReel"
                @hideModal="hideModal"
                @loadData="editUploadReel"
                @updateUrlEditModal="updateUrlEditModal"
              />
            </b-tab>
          </b-tabs>
          <!-- EDIT REEL FORM IF THERE ARE NO LANGUAGES AVAILABLE-->
          <reels-edit
            v-else
            :language="base"
            :base="base"
            :original-object="playReel"
            @editUploadReel="editUploadReel"
            @hideModal="hideModal"
            @loadData="editUploadReel"
            @updateUrlEditModal="updateUrlEditModal"
          />
        </b-col>
        <b-col
          cols="4"
          class="d-flex justify-content-center"
        >
          <video
            id="demo"
            :key="playReel.key"
            class="video-js vjs-default-skin vjs-big-play-centered cursor-pointer border-reel"
            data-setup="{&quot;liveui&quot;: true}"
            controls
          >
            <source
              :src="playReel.fileUrl"
              type="video/mp4"
            >
          </video>
        </b-col>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BFormInput,
  BImg,
  BPagination,
  BRow,
  BTable,
  BTab,
  BTabs,
  VBTooltip,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import localesLanguages from '@core/utils/languageModels'
import ReelsUpload from './ReelsUpload.vue'
import ReelsEdit from './ReelsEdit.vue'
import ViewFileModal from '../../common/ViewFileModal.vue'
import 'videojs-markers'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'

export default {
  components: {
    BBadge,
    BButton,
    BCard,
    BCardHeader,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BFormInput,
    BImg,
    BPagination,
    BRow,
    BTable,
    BTab,
    BTabs,
    ReelsUpload,
    ReelsEdit,
    ViewFileModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      base: '',
      chosenMethod: '',
      columns: [
        { key: 'icon', label: '' },
        { key: 'name', label: this.$t('nameFile') },
        { key: 'description', label: this.$t('resources.tableHeader.description') },
        { key: 'nViews', label: this.$t('code.nVis2') },
        { key: 'date', label: this.$t('dateUp') },
        { key: 'active', label: this.$t('reels.active') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },
      ],
      confirmDelete: false,
      confirmDownload: false,
      currentPage: 1,
      dataOverlay: '',
      deleting: false,
      descriptionValidation: null,
      downloadEnd: 0,
      downloading: false,
      downloadProgress: 0,
      fileInput: [],
      files: null,
      headers: null,
      languageService: true,
      locales: [],
      nameValidation: null,
      newFiles: true,
      newUrl: '',
      newUrlValidation: null,
      nReel: 0,
      playReel: null,
      perPage: 10,
      rows: 0,
      search: '',
      searchFiles: [],
      searchResults: null,
      showDropCard: true,
      showUrlInput: true,
      typeFile: null,
      uploadEditFile: [],
      uploadFilesList: [],
      uploadMethods: [
        { value: '', text: this.$t('chooseOption'), disabled: true },
        { value: 'BUK', text: this.$t('reels.uploadReel') },
        { value: 'DIR', text: this.$t('url') },
      ],
      uploadUrlsList: [],
      userData: getUserData(),
      valuesData: [],
    }
  },
  computed: {
    paginatedData() {
      const start = this.perPage * (this.currentPage - 1)
      const end = start + this.perPage
      return this.searchFiles.slice(start, end)
    },
  },
  watch: {
    fileInput() {
      try {
        this.fileInput.forEach(element => {
          const newKey = Math.random().toString(36).substring(7)
          let values
          if (this.valuesData.length) {
            values = this.valuesData.find(item => item.file.name === element.name)
          }
          const newFile = {
            key: this.newFiles ? newKey : values.key,
            file: this.newFiles ? element : values.file,
            description: this.newFiles ? '' : values.description,
            newName: this.newFiles ? element.name : values.newName,
            thumbnail: this.newFiles ? '' : values.thumbnail,
          }
          if (this.chosenMethod === 'DIR') {
            newFile.url = this.newFiles ? element : values.url
            this.uploadUrlsList.push(newFile)
          } else if (this.chosenMethod === 'BUK') {
            newFile.file = this.newFiles ? element : values.file
            this.uploadFilesList.push(newFile)
            if (this.$refs.reelsUpload && typeof this.$refs.reelsUpload.setThumbnails === 'function') {
              this.$refs.reelsUpload.setThumbnails()
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      if (this.fileInput.length === 0) {
        this.showDropCard = true
        this.showUrlInput = true
      }
    },
    uploadFilesList() {
      if (this.uploadFilesList.length > 0) {
        this.showDropCard = true
        this.showUrlInput = false
      }
    },
    uploadUrlsList() {
      if (this.uploadUrlsList.length > 0) {
        this.showDropCard = false
        this.showUrlInput = true
      }
    },
    searchFiles() {
      this.rows = this.searchFiles.length
    },
  },
  mounted() {
    this.getHeaders()
    this.getClient()
    this.totalCount()
  },
  methods: {
    addReelFile(event) {
      event.dataTransfer.files.forEach(element => {
        this.uploadFilesList.push({
          key: Math.random().toString(36).substring(7),
          file: element,
          description: '',
          newName: '',
          thumbnail: '',
        })
      })
      this.uploadFilesList.forEach(element => {
        // eslint-disable-next-line no-param-reassign
        element.newName = element.file.name
      })
      this.uploadFilesList = this.uploadFilesList.filter(reel => reel.file.type.includes('video'))
      if (this.$refs.reelsUpload && typeof this.$refs.reelsUpload.setThumbnails === 'function') {
        this.$refs.reelsUpload.setThumbnails()
      }
      this.chosenMethod = 'BUK'
      event.preventDefault()
    },
    addReelUrl() {
      this.checkUrl(this.newUrl, false)
      if (this.newUrlValidation && this.uploadUrlsList.length === 0) {
        this.uploadUrlsList.push({
          key: Math.random().toString(36).substring(7),
          url: this.newUrl,
          description: '',
          newName: (this.newUrl.split('/')).pop(),
          thumbnail: '',
        })
      }
      this.chosenMethod = 'DIR'
    },
    redirectReels() {
      this.$router.push({ name: 'contents-reels' })
    },
    checkUrl(url) {
      if (url && this.isUrlValid(url)) {
        this.newUrlValidation = true
      } else {
        this.newUrlValidation = false
      }
    },
    cleanNewUrl() {
      this.newUrl = ''
      this.newUrlValidation = null
    },
    cleanResourcesUploaded() {
      this.emptyReels([])
      if (this.chosenMethod === 'DIR') {
        this.cleanNewUrl()
      }
    },
    deleteReel(idReel) {
      this.$swal({
        title: this.$t('dataGeneric.atention'),
        text: this.$t('code.title'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('next'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              variables: {
                id: idReel,
              },
              query: `
                mutation ($id: ID!) {
                  deleteReel(id:$id) {
                    found
                    deletedId
                  }
                }
              `,
            }).then(response => {
              messageError(response, this)
              if (response.data.data.deleteReel.found) {
                showToast(this.$t('deleteSuccess'), 1, this)
                this.confirmDelete = false
                const index = this.searchFiles.findIndex(element => element.id === response.data.data.deleteReel.deletedId)
                this.searchFiles.splice(index, 1)
                if (this.paginatedData.length === 0) {
                  this.currentPage -= 1
                }
                this.totalCount()
              } else {
                showToast(this.$t('deleteError'), 2, this)
              }
            }).catch(res => {
              // eslint-disable-next-line
              console.log(res)
            })
        }
      })
    },
    downloadReel(file) {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', file.fileUrl, true)
      xhr.responseType = 'blob'

      xhr.onload = function load() {
        const urlCreator = window.URL || window.webkitURL
        const videoUrl = urlCreator.createObjectURL(this.response)
        const tag = document.createElement('a')
        tag.href = videoUrl
        tag.download = file.name
        document.body.appendChild(tag)
        tag.click()
        document.body.removeChild(tag)
      }
      xhr.onloadend = () => {
        this.confirmDownload = false
        showToast(this.$t('downloadSuccess'), 1, this)
      }
      xhr.onprogress = event => {
        this.downloadEnd = event.total
        this.downloadProgress = event.loaded
      }
      xhr.onerror = err => {
        showToast(this.$t('downloadError'), 2, this)
        // eslint-disable-next-line
        console.log(err)
      }
      xhr.send()
    },
    editUploadReel(file) {
      const index = this.searchFiles.findIndex(element => element.id === file.id)
      this.searchFiles.splice(index, 1, file)
    },
    emptyReels(data, method) {
      const files = []
      if (method === 'DIR') {
        data.forEach(element => {
          this.valuesData.push(element)
          files.push(element.file)
        })
        if (data.length === 0) {
          this.cleanNewUrl()
        }
      } else if (method === 'BUK') {
        data.forEach(element => {
          this.valuesData.push(element)
          files.push(element.file)
        })
      }
      this.newFiles = false
      this.fileInput = files
      this.uploadFilesList = []
      this.uploadUrlsList = []
    },
    formatDate(value) {
      const subscriptionDate = new Date(value)
      const newDate = subscriptionDate.toLocaleDateString('en-GB')
      return newDate
    },
    getClient() {
      axios
        .post('', {
          variables: {
            client: this.userData.profile.client.id,
          },
          query: `
            query ($client: ID!) {
              client(id:$client){
                  defaultLanguage
                  availableLanguages
              }
            }
          `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client
          const arrayLanguages = this.getLocaleFormat(availableLanguages)

          this.languageService = arrayLanguages.length > 1
          this.base = `${this.getLocaleFormat(res.data.data.client.defaultLanguage)}`
          const languages = localesLanguages.filter(locale => arrayLanguages.includes(locale.locale))

          const baseObject = languages.find(obj => obj.locale === this.base)
          const filteredArray = languages.filter(obj => obj.locale !== this.base)
          this.locales = [baseObject, ...filteredArray]
        })
        .catch(() => {
        })
    },
    getHeaders() {
      let defaultLang = 'es'
      try {
        defaultLang = this.userData.profile.client.defaultLanguage
      } catch (error) {
      // eslint-disable-next-line
      console.log(error)
      }
      this.headers = {
        'Accept-Language': defaultLang,
        'Content-Language': defaultLang,
      }
    },
    getLocaleFormat(languagesString) {
      const languages = languagesString.split('|')

      const languagesFormatted = languages.map(language => {
        const parts = language.split('-')
        const languageFormatted = parts
          .map(parte => parte.charAt(0).toUpperCase() + parte.slice(1)).join('')
        return languageFormatted
      })
      return languagesFormatted
    },
    hideModal(modal) {
      this.$refs[modal].hide()
      this.playReel = null
    },
    isUrlValid(url) {
      if (url.trim() === '') {
        return false
      }
      const videoFormatRegex = /\.(mp4|mov|avi|mkv|mpg|mpeg|wmv)$/i
      const inyectionRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
      if (videoFormatRegex.test(url) && inyectionRegex.test(url)) return true
      return false
    },
    recentUpload(uploadedReels) {
      this.searchFiles.push(uploadedReels)
      this.totalCount()
    },
    selectFile() {
      this.newFiles = true
      this.chosenMethod = 'BUK'
      document.getElementById('fileInput').click()
    },
    totalCount() {
      axios
        .post('', {
          variables: {
            client: this.userData.profile.client.id,
          },
          query: `
            query ($client:ID) {
              allReel (client:$client) {
                totalCount
              }
            }
          `,
        })
        .then(result => {
          messageError(result, this)
          this.nReel = result.data.data.allReel.totalCount
        })
    },
    updateReelStatus(item) {
      axios
        .post('', {
          variables: {
            id: item.id,
            isActive: !item.isActive,
          },
          query: `
            mutation ($id: ID!, $isActive: Boolean) {
              updateReel(id:$id, input:{isActive:$isActive}) {
                reel{
                  id,
                  isActive
                }
              }
            }      
          `,
        }).then(response => {
          messageError(response, this)
          if (response.data.data.updateReel.reel.isActive === item.isActive) {
            const checkboxes = document.querySelectorAll(`#reel${item.id}`)
            checkboxes.forEach(checkbox => {
              // eslint-disable-next-line no-param-reassign
              checkbox.checked = response.data.data.updateReel.reel.isActive
            })
          } else {
            const reelUpdateIndex = this.searchFiles.findIndex(reel => reel.id === item.id)
            if (reelUpdateIndex !== -1) {
              this.searchFiles[reelUpdateIndex].isActive = response.data.data.updateReel.reel.isActive
            }
          }
        }).catch(res => {
          // eslint-disable-next-line
          console.log(res)
        })
    },
    searching() {
      this.currentPage = 1
      this.searchResults = this.searchFiles.filter(element => {
        const nameLowerCase = element.name.toLowerCase()
        const searchLowerCase = this.search.toLowerCase()
        return nameLowerCase.includes(searchLowerCase)
      })
    },
    showModal(modal, reel) {
      this.playReel = reel
      this.playReel.key = Math.random().toString(36).substring(7)
      this.$nextTick(() => {
        this.$refs[modal].show()
      })
    },
    updateUrlEditModal(newUrl) {
      this.playReel.key = Math.random().toString(36).substring(7)
      this.playReel.fileUrl = newUrl
    },
  },
}
</script>

<style scoped>
  .customColumn {
    overflow: hidden;
    width: 200px;
    text-overflow: ellipsis;
  }
  .border-reel {
    height: auto;
    border: 1px solid white;
    border-radius: 10px;
  }
  textarea::-webkit-scrollbar {
    display: none
  }
</style>
