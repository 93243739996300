var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"reelUpload"}},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-card',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between"},[_c('b-card-header',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('h2',[_vm._v(_vm._s(_vm.$t('reels.reelUpload')))]),_c('h6',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t('procFile'))+" ")])])],1)],1),_c('div',{staticClass:"d-flex",staticStyle:{"padding":"1.5rem 1rem"}},[(_vm.method == 'BUK')?_c('b-col',[_c('b-button',{staticClass:"text-nowrap",attrs:{"size":"md","variant":"success"},on:{"click":function($event){$event.stopPropagation();return (function (event) { return _vm.validationFormInfo(); })($event)}}},[_vm._v(" "+_vm._s(_vm.$t('reels.uploadReels'))+" ")])],1):_vm._e(),_c('b-col',{staticClass:"pr-0"},[_c('feather-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"XCircleIcon","size":"30"},on:{"click":_vm.cleanUpload}})],1)],1)],1)],1),_c('b-card-body',[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-table',{attrs:{"id":"table","striped":true,"sticky-header":"500px","no-border-collapse":true,"responsive":"sm","fixed":"","items":_vm.method == 'BUK' ? _vm.filesUpload : _vm.urlsUpload,"fields":_vm.columns},scopedSlots:_vm._u([{key:"cell(icon)",fn:function(data){return [_c('div',{staticClass:"embed-responsive embed-responsive-16by9"},[_c('b-img',{staticClass:"reel-display",attrs:{"id":data.item.key,"loading":"lazy","src":data.item.thumbnail ? data.item.thumbnail : require('@/assets/images/backend/placeholder-video.png'),"alt":"image"}})],1)]}},{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"text-nowrap align-items-center"},[_c('validation-provider',{attrs:{"name":"Name","rules":{
                      required: true,
                      min: 3,
                      regex: /^[a-zA-Z0-9\s.,'!?()-áéíóú]+$/
                    }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-input',{attrs:{"id":("name-" + (data.item.key)),"state":validationContext.errors.length > 0 ? false : null,"placeholder":_vm.$t('resources.tableHeader.name'),"maxlength":"150"},model:{value:(data.item.newName),callback:function ($$v) {_vm.$set(data.item, "newName", $$v)},expression:"data.item.newName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.fix(validationContext)))])]}}],null,true)})],1)]}},{key:"cell(description)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('validation-provider',{attrs:{"name":"description","rules":{
                      regex: /^[a-zA-Z0-9\s.,'!?()-áéíóú]*$/
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"overflow-scroll",attrs:{"id":("description-" + (data.item.key)),"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('resources.tableHeader.description'),"maxlength":"180","rows":"2","no-resize":""},model:{value:(data.item.description),callback:function ($$v) {_vm.$set(data.item, "description", $$v)},expression:"data.item.description"}})]}}],null,true)})],1)]}},{key:"cell(method)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[(_vm.method == 'BUK')?[_c('p',[_vm._v(_vm._s(_vm.$t('reels.bukMethod')))])]:(_vm.method == 'DIR')?[_c('p',[_vm._v(_vm._s(_vm.$t('url')))])]:_vm._e()],2)]}},{key:"cell(buttons)",fn:function(data){return [_c('div',{staticClass:"text-nowrap d-flex justify-content-end",staticStyle:{"max-width":"100%"}},[_c('b-row',{staticClass:"form-group"},[(_vm.method == 'DIR')?_c('b-col',[_c('b-button',{attrs:{"size":"md","variant":"success"},on:{"click":function($event){$event.stopPropagation();return (function (event) { return _vm.validateInputs(data.item.key); })($event)}}},[_vm._v(" "+_vm._s(_vm.$t('reels.uploadReel'))+" ")])],1):_vm._e(),_c('b-col',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"XCircleIcon","size":"30"},on:{"click":function($event){return _vm.cleanElement(data.item.key)}}})],1)],1)],1)]}}])}),_c('b-overlay',{attrs:{"show":_vm.busy,"no-wrap":"","variant":"transparent","opacity":1.00,"blur":'2px',"rounded":"lg"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('feather-icon',{attrs:{"icon":"UploadIcon","font-scale":"3","size":"30"}}),_c('br'),_vm._v(" "+_vm._s(_vm.$t('reels.uploading'))+" ")],1)]},proxy:true}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }